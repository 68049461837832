import {
  applyTween,
  useMotionOptions,
  useShowInstructions,
} from '@backstage-components/base';
import {css} from '@emotion/react';
import {motion} from 'framer-motion';
import {useSubscription} from 'observable-hooks';
import {useState, type FC, type PropsWithChildren} from 'react';
import {Container as StyledContainer} from './AccessCode.styled';
import {
  ComponentDefinition,
  type AccessCodeComponentDefinition,
} from './AccessCodeDefinition';

const MotionContainer = motion(StyledContainer);

interface ContainerProps {
  className: string;
  definition: AccessCodeComponentDefinition;
  id: string;
}

/**
 * Manages `animationStates` around the `children` given to this component.
 */
export const AccessCodeContainer: FC<PropsWithChildren<ContainerProps>> = ({
  children,
  className,
  definition,
  id,
}) => {
  const {props} = definition;
  const {animationStates = []} = props;
  const [activeVariant, setActiveVariant] = useState<string | undefined>(
    undefined
  );
  const motionOptions = useMotionOptions(animationStates);

  const styles = css`
    ${definition.style}
    ${definition.props.styleAttr}
  `;

  const {observable, broadcast} = useShowInstructions(
    ComponentDefinition.instructions,
    definition
  );
  useSubscription(observable, (instruction) => {
    switch (instruction.type) {
      case 'AccessCode:animationState': // intentional fall through
      case 'Animate:to-state':
        applyTween(instruction, animationStates, setActiveVariant);
        break;
    }
  });

  return (
    <MotionContainer
      id={id}
      className={className}
      css={styles}
      {...motionOptions}
      animate={activeVariant}
      onAnimationComplete={() => {
        broadcast({
          type: 'Animate:on-complete',
          meta: {stateName: activeVariant},
        });
      }}
    >
      {children}
    </MotionContainer>
  );
};
